import React from "react"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import LogoLight from '../assets/images/voice-of-Japan-logo.svg'

export default function Header() {
  const intl = useIntl()
  console.log(intl)
  return (
    <header id="header" className="container mx-auto">
      <nav className="navigation col navigation--primary">
        <div className="logo">
          <Link to="/">
            <LogoLight height={100} width={100}/>
          </Link>
        </div>
        <div className="navigation__links-wrap">
          <button className="navigation__button" aria-label="mobile menu">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul className="navigation__links">
            <li>
              <a href="#portfolio" className="js-trigger">
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#brands"
                className="js-trigger"
                style={{"transitionDelay": "0.1s"}}
              >
                Brands
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="js-trigger"
                data-mt-tolerance="-50"
                style={{"transitionDelay": "0.2s"}}
              >
                Testimonials
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="js-trigger"
                data-mt-tolerance="-50"
                style={{"transitionDelay": "0.3s"}}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="js-trigger"
                data-mt-tolerance="-50"
                style={{"transitionDelay": "0.4s"}}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="js-trigger"
                data-mt-tolerance="-80"
                style={{"transitionDelay": "0.5s"}}
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="navigation__overlay"></div>
          <a
            href="#header"
            title="Go to top"
            className="scroll-to-top-btn js-trigger"
          >
            <i className="icofont-long-arrow-up icofont-lg"></i>
          </a>
        </div>
      </nav>
    </header>
  )
}
