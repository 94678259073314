import React, { useContext } from "react"

import { PageContext } from "../utils/contexts"
// import ContactForm from "../components/contact-form"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Contact from "../components/Contact"

export default function HomePage() {
  return (
    <>
      <Header />
      <Hero />
      <Contact />
    </>
  )
}
