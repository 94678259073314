import React, { useState } from "react"
import validatejs from "validate.js"
import cx from "classnames"

const contact_heading = "Send me a message"
const contact_description =
  "acon ipsum dolor amet fatback pork chop chicken tenderloin sirloin meatball filet mignon buffalo ball tip porchetta bresaola"

const constraints = {
  name: {
    presence: true,
  },
  email: {
    presence: true,
    email: true,
  },
  message: {
    presence: true,
  },
}

function handleSubmit(event, setFormErrors, setFormSubmitted) {
  event.preventDefault()
  const form = event.target
  const errors = validatejs.validate(form, constraints)
  setFormErrors(errors || {})
  console.log(errors)
  if (typeof errors === "object") {
    return false
  }
  const formData = new FormData(form)
  formData.append("form-name", form.getAttribute("name"))
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => {
      setFormSubmitted(true)
    })
    .catch(error => alert(error))
}

export default function Contact() {
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <section id="contact" className="contact section container mx-auto">
      <div className="flex">
        <div className="col w-full md:w-6/12 mx-auto text-center">
          <h4 className="section__heading text-2xl  mx-auto mb-4">
            {contact_heading}
          </h4>
          <p className="mb-16">{contact_description}</p>
          {formSubmitted ? (
            <div>Thank you, we'll be in touch shortly</div>
          ) : (
            <Form setFormSubmitted={setFormSubmitted} />
          )}
        </div>
      </div>
    </section>
  )
}

function Form({ setFormSubmitted }) {
  const [formErrors, setFormErrors] = useState({})
  return (
    <form
      id="contact-form"
      name="contact"
      data-netlify="true"
      className="text-left shadow-lg border border-gray-100 px-8 py-12"
      noValidate={true}
      onSubmit={event => handleSubmit(event, setFormErrors, setFormSubmitted)}
    >
      <div className="form-group mb-6">
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          id="name"
          className={cx("input", { "input--error": formErrors.name })}
          name="name"
        />
        <InputError error={formErrors.name} />
      </div>
      <div className="form-group mb-6">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          className={cx("input", { "input--error": formErrors.email })}
          name="email"
        />
        <InputError error={formErrors.email} />
      </div>
      <div className="form-group mb-8">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          className={cx("input", "h-32", {
            "input--error": formErrors.message,
          })}
          name="message"
        ></textarea>
        <InputError error={formErrors.message} />
      </div>
      <button type="submit" className="btn btn--primary btn--full">
        Submit
      </button>
    </form>
  )
}

function InputError({ error }) {
  if (error) {
    return <span className="block mt-2 text-quaternary text-sm">{error}</span>
  }
  return null
}
